<template>
  <v-app>
    <div>
      <div class="clearfix"></div>
      <div
        class="row justify-content-center view-switcher opps-custom-margin"
        v-if="!choose_plan"
      >
        <img
          height="250px"
          src="../../assets/images/opps.svg"
          width="400px"
          class="mx-auto"
        />
        <div class="col-12 text-center oops-text text-md-subtitle-1">
          <h3 class="mt-3">Oops!</h3>
          <p>
            Looks like your free trial just ended. To continue tracking your
            projects
          </p>
        </div>
        <!--btn-inte-rep-->
        <div class="mx-auto">
          <v-btn
            color="#2758f6"
            width="90%"
            class="text-capitalize create-project-button"
            @click="choose_plan = true"
          >
            Choose a Plan
          </v-btn>
        </div>
      </div>
      <div class="mt-5 ml-auto mr-auto mb-5 pay-method">
        <Billing v-if="choose_plan" />
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SubscriptionExpired",
  components: {
    Billing: () => import("@/views/Billing"),
  },
  data() {
    return {
      choose_plan: false,
    };
  },
  computed: mapState("custom", ["selected_company"]),
  methods: {
    openModal(product) {
      if (this.check_subscription === false) {
        this.$root.$emit("bv::show::modal", "modal-addCard");
        this.$root.$emit("selectedPlan", {
          plan: this.cardDetail.stripe_plan
            .substring(0, this.cardDetail.stripe_plan.length - 5)
            .replace(/^\w/, (c) => c.toUpperCase()),
          product: product.replace(/^\w/, (c) => c.toUpperCase()),
          amount: this[product + "_amount"],
        });
      } else {
        this.update_payment_card();
      }
    },
    update_payment_card() {
      let data = {
        company_id: this.$route.params.id,
        stripe_plan: this.cardDetail.stripe_plan,
        stripe_product: this.cardDetail.stripe_product,
      };
      this.$store
        .dispatch("custom/update_payment_card", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error) {
            let errorElement = document.getElementById(this.errorElementId);
            errorElement.textContent = response.data.error_msg;
            this.createSetupIntent();
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.$store.dispatch("custom/company_payments_cards", {
              company_id: this.$route.params.id,
            });
            // window.location.href = '/dashboard';
          }
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          let expire_year = error.response.data.errors["expiry_year"];
          if (expire_year) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: expire_year,
              snackbarColor: "red",
            });
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Something want wrong Please try again!",
              snackbarColor: "red",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.view-switcher .oops-text {
  color: #2758f6;
  font-family: "Poppins", "sans-serif";
}
.create-project-button {
  width: 112%;
  height: 48px;
  border-radius: 3px !important;
  background-color: #2758f6;
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.create-project-button:hover {
  color: white;
  box-shadow: 0px 5px 12px 0px rgba(23, 70, 222, 0.4) !important;
  transform: scale(1.01);
  background-color: #2758f6 !important;
  cursor: pointer !important;
}

.opps-custom-margin {
  margin-top: 4rem !important;
}
</style>
